.apply-threshold {
  width: 100%;
  height: 200px;
  text-align: center;
  color: white;
  font-size: 40px;
  padding-top: 100px;
  background: rgb(32, 173, 251);
  background: linear-gradient(
    145deg,
    rgba(32, 173, 251, 1) 0%,
    rgba(189, 71, 255, 1) 100%
  );
}

.apply-form-wrapper {
  padding-top: 40px;
}

.apply-form {
  border: none;
  outline: none;
  box-shadow: none;
  margin: auto;
  display: block;
}
