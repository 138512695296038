.plan-block {
  width: calc(33% - 44px);
  margin: 10px;
  padding: 10px;
  float: left;
  background-color: white;
  border-radius: 5px;
  border: 2px solid #eee;
}

@media only screen and (max-width: 1000px) {
  .plan-block {
    width: calc(100% - 44px);
    min-height: 50px;
  }
}

.plan-block-heading-row {
  width: 100%;
  display: table;
}

.plan-block-name {
  float: left;
  font-size: 25px;
  color: #777;
}

.sold-out {
  text-decoration: line-through;
  color: #aaa;
}

.sold-out-text {
  padding-left: 10px;
  font-size: 17px;
  font-weight: bold;
  color: rgb(230, 73, 73);
  position: relative;
  bottom: 2px;
  display: inline-block;
}

@media only screen and (max-width: 800px) {
  .sold-out-text {
    font-size: 15px;
    padding-left: 5px;
    bottom: 1px;
  }
}

.plan-block-price {
  float: right;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #888;
  color: white;
}

.plan-block-info {
  min-height: 20px;
  padding: 20px 0;
  font-size: 13px;
  color: #666;
}

.plan-block-feature-block {
  width: 100%;
  display: table;
}

.plan-block-feature-row {
  border: 1px solid #ccc;
  border-radius: 30px;
  display: table;
  padding: 5px;
  width: calc(100% - 10px);
  margin-bottom: 10px;
}

.plan-block-feature-quantity {
  float: left;
  width: 20px;
  height: 19px;
  text-align: center;
  padding-top: 1px;
  border-radius: 100%;
  font-size: 12px;
  color: white;
  background-color: #888;
  font-weight: bold;
}

.plan-block-feature-description {
  float: left;
  font-size: 12px;
  padding-left: 5px;
  color: #555;
  padding-top: 1px;
}

.plan-block-action-row {
  width: 100%;
  padding: 10px 0;
}

.plan-block-action-button {
  margin: auto;
  display: table;
  color: white;
  background-color: rgb(11, 202, 11);
  padding: 6px 25px 8px 25px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}
