body {
  margin: 0;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
  background-color: white;
}

a {
  text-decoration: none;
}

.emphasis {
  font-weight: bolder;
}

#logo-wrapper {
  width: 200px;
  position: absolute;
  left: 20px;
  top: 20px;
}

#logo {
  width: 100%;
}

@media only screen and (max-width: 800px) {
  #logo-wrapper {
    display: block;
    left: 20px;
    top: 20px;
    width: 100px;
  }
}
