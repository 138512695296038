#landing-page-threshold {
  width: 100%;
  height: 400px;
  background: rgb(32, 173, 251);
  background: linear-gradient(
    145deg,
    rgba(32, 173, 251, 1) 0%,
    rgba(189, 71, 255, 1) 100%
  );
}

@media only screen and (max-width: 800px) {
  #landing-page-threshold {
    height: 350px;
  }
}

#nav-buttons {
  position: absolute;
  top: 20px;
  right: 20px;
}

@media only screen and (max-width: 800px) {
  #nav-buttons {
    background-color: white;
    padding: 4px 8px;
    border-radius: 5px;
    top: 25px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  }
}

.nav-button {
  color: white;
  font-size: 18px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  transition: all 0.2s;
}

.nav-button:hover {
  border-color: white;
}

@media only screen and (max-width: 800px) {
  .nav-button {
    font-size: 15px;
    color: #7072ff;
    font-weight: 600;
  }
}

#threshold-verbiage-wrapper {
  width: 100%;
  margin: auto;
  text-align: center;
  color: white;
  position: relative;
  top: 100px;
}

#threshold-verbiage-wrapper-top {
  font-size: 40px;
}

#threshold-verbiage-wrapper-bottom {
  font-size: 30px;
}

@media only screen and (max-width: 800px) {
  #threshold-verbiage-wrapper-top {
    font-size: 30px;
  }

  #threshold-verbiage-wrapper-bottom {
    font-size: 20px;
  }
}

.free-session-button {
  background-color: #11dd11;
  margin: auto;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  display: table;
  text-align: center;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  padding: 10px 40px;
  margin-top: 50px;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.2s;
}

.free-session-button:hover {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
}

.landing-page-section {
  min-height: 100px;
  width: calc(100% - 160px);
  display: table;
  padding: 80px 80px;
  background-color: white;
}

@media only screen and (max-width: 1000px) {
  .landing-page-section {
    padding: 20px 10px;
    width: calc(100% - 20px);
    padding-bottom: 0;
  }
}

.landing-page-section.why-mentor-section {
  background-color: rgb(247, 247, 247);
}

.landing-page-section.what-is-it-like-section {
  background-color: rgb(247, 247, 247);
}

.landing-page-section.plans-section {
  background-color: rgb(247, 247, 247);
}

.landing-page-section-heading {
  font-size: 40px;
  padding: 0 0 20px 20px;
  color: #555;
  font-weight: 500;
}

@media only screen and (max-width: 1000px) {
  .landing-page-section-heading {
    font-size: 25px;
    padding: 0 0 0px 20px;
  }
}
