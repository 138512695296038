.info-block {
  width: calc(33% - 84px);
  float: left;
  border: 2px solid #eee;
  margin: 10px 20px;
  padding: 20px;
  border-radius: 5px;
  background-color: white;
  min-height: 140px;
}

@media only screen and (max-width: 1000px) {
  .info-block {
    width: calc(100% - 64px);
    min-height: 50px;
    margin: 10px;
  }
}

.info-block-heading {
  width: calc(100%);
  padding-bottom: 10px;
  font-size: 30px;
  color: #666;
}

@media only screen and (max-width: 800px) {
  .info-block-heading {
    font-size: 20px;
  }
}

.info-block-body {
  font-size: 14px;
  color: #777;
}
