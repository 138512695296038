#threshold-lines {
  width: 100%;
  position: absolute;
  top: 190px;
  left: 0;
  margin: auto;
  z-index: 1;
}

@media only screen and (max-width: 800px) {
  #threshold-lines {
    top: 260px;
  }
}

#tech-talk-threshold {
  width: 100%;
  height: 250px;
  text-align: center;
  color: white;
  font-size: 40px;
  padding-top: 50px;
  background: rgb(32, 173, 251);
  background: linear-gradient(
    145deg,
    rgba(32, 173, 251, 1) 0%,
    rgba(189, 71, 255, 1) 100%
  );
}

@media only screen and (max-width: 800px) {
  #tech-talk-threshold {
    font-size: 30px;
    padding-top: 100px;
  }
}

#add-to-invite-button {
  padding: 10px 40px;
  /* padding-top: 12px; */
  background-color: rgb(24, 192, 24);
  outline: none;
  border: none;
  z-index: 10;
  color: white;
  font-size: 16px;
  border-radius: 5px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 220px;
  left: calc(50% - 110px);
  cursor: pointer;
  transition: all 0.2s;
  font-weight: 600;
}

#add-to-invite-button:hover {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
}

@media only screen and (max-width: 800px) {
  #add-to-invite-button {
    top: 250px;
  }
}

.tech-talk-section-wrapper {
  width: 100%;
  padding: 60px 0;
  min-height: 100px;
  text-align: center;
}

.tech-talk-section-wrapper.blue {
  background-color: rgba(32, 173, 251, 1);
  box-shadow: 0 0px 0.5px rgba(0, 0, 0, 0.025), 0 0px 1.3px rgba(0, 0, 0, 0.036),
    0 0px 2.4px rgba(0, 0, 0, 0.045), 0 0px 4.2px rgba(0, 0, 0, 0.054),
    0 0px 7.9px rgba(0, 0, 0, 0.065), 0 0px 19px rgba(0, 0, 0, 0.09);
}

.tech-talk-section-heading {
  text-align: center;
  font-weight: 600;
  font-size: 40px;
  color: rgba(32, 173, 251, 1);
}

@media only screen and (max-width: 800px) {
  .tech-talk-section-heading {
    font-size: 25px;
  }
}

.tech-talk-section-heading.white {
  color: white;
}

#tech-lead-logos {
  width: 600px;
  margin: auto;
}

@media only screen and (max-width: 800px) {
  #tech-lead-logos {
    width: calc(100% - 20px);
    margin-top: 20px;
  }
}

#community-quotes {
  width: 1000px;
  margin: auto;
  margin-top: 40px;
}

@media only screen and (max-width: 800px) {
  #community-quotes {
    width: calc(100% - 20px);
  }
}

#community-logos {
  width: 600px;
  margin-top: 40px;
}

@media only screen and (max-width: 800px) {
  #community-logos {
    width: calc(100% - 20px);
  }
}

#tech-talk-cta-section {
  width: 100%;
  padding: 40px 0;
  clear: both;
  background-color: #c170ff;
  box-shadow: 0 0.4px 2.2px rgba(0, 0, 0, 0.034),
    0 1px 5.3px rgba(0, 0, 0, 0.048), 0 1.9px 10px rgba(0, 0, 0, 0.06),
    0 3.4px 17.9px rgba(0, 0, 0, 0.072), 0 6.3px 33.4px rgba(0, 0, 0, 0.086),
    0 15px 80px rgba(0, 0, 0, 0.12);
}

#add-to-invite-button-bottom-cta {
  padding: 10px 40px;
  padding-top: 11px;
  background-color: rgb(24, 192, 24);
  outline: none;
  border: none;
  z-index: 10;
  color: white;
  font-size: 18px;
  border-radius: 5px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  margin: auto;
  display: table;
  transition: all 0.2s;
  font-weight: 600;
}

#add-to-invite-button-bottom-cta:hover {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
}
